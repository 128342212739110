import {illiquidsTourInit} from './../bootstrap-tour/bootstrap-tour.js';

let $arDataTables = $('.js-data-table');

$arDataTables.each(function (index, el) {
	$(el)
		.on('init.dt', function () {
			$(el)
				.siblings('.dataTables_length')
				.find('select')
				.select2({
					theme: 'filled',
					minimumResultsForSearch: Infinity
				});
		})
		.dataTable({
			language: {
				url: localize({
					ru: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Russian.json',
					en: '//cdn.datatables.net/plug-ins/1.10.15/i18n/English.json'
				})
			},
			dom: 'B<"clear">lfrtip',
			buttons: [
				{
					extend: 'excel',
					className: 'btn btn--bordered btn--excel'
				}
			]
		});
});

/*
	TENDERS
*/

const Promise = require('bluebird');
const verge = require('verge');
const Datepicker = require('air-datepicker');
const moment = require('moment');
const List = require('list.js');
const queryString = require('query-string');
const debounce = require('throttle-debounce/debounce');
const $table = $('.js-data-table--custom');
const cookie = require('cookie_js');
const jszip = require('jszip');

let dataLanguageConfig = require('./data-table.json');
window.JSZip = jszip;

class SuppliersTable {
	constructor(table) {
		this.table = table;
		this.dt;
		this.filterValue = '';
		this.tendersFilterLists = [];
	}

	create() {
		let self = this,
			table = this.table,
			path = $(table).closest('.table').attr('data-ajax-path'),
			dataColumns = [],
			dataAjax = {},
			domStr = '<"clear">fr<"data-table-scroll"t>pil';

		if ($(table).closest('.table').attr('id') == 'tenders-table-illiquids') {
			domStr = '<"clear">fBr<"data-table-scroll"t>pil';
			dataColumns = [
				{
					data: null,
					defaultContent: '<div class="checkbox checkbox--compact"><label class="checkbox__label" for=""><input class="js-checkbox--select-row checkbox__input" name="" id="" type="checkbox"></label></div>'
				},
				{
					data: 'company',
					search: {
						regex: true
					}
				},
				{
					data: 'ens_code',
					search: {
						regex: true
					}
				},
				{
					data: 'product',
					search: {
						regex: true
					}
				},
				{
					data: 'materials_group',
					search: {
						regex: true
					}
				},
				{
					data: 'materials_subgroup',
					search: {
						regex: true
					}
				},
				{
					data: 'mark',
					search: {
						regex: true
					}
				},
				{
					data: 'tech_characteristics',
					search: {
						regex: true
					}
				},
				{
					data: 'gost',
					search: {
						regex: true
					}
				},
				{
					data: 'units',
					search: {
						regex: true
					}
				},
				{
					data: 'quantity',
					search: {
						regex: true
					}
				},
				{
					data: 'price',
					search: {
						regex: true
					}
				}
			];
		} else {
			dataColumns = [
				{
					data: 'subject_procurement',
					class: 'overflowed'
				},
				{
					data: 'method_carrying',
					search: {
						regex: true
					}
				},
				{
					data: 'deadline',
					search: {
						regex: true
					}
				},
				{
					data: 'how_apply',
					search: {
						regex: true
					}
				},
				{
					data: 'customer',
					search: {
						regex: true
					}
				},
				{
					data: 'address',
					search: {
						regex: true
					}
				},
				{
					data: 'organizer',
					search: {
						regex: true
					}
				},
				{
					data: 'contacts',
					search: {
						regex: true
					}
				}
			];
		}

		let buttonCommon = {
			exportOptions: {
				format: {
					header: function (data, row, column, node) {
						return column.className.indexOf('tenders-filter') >= 0 ?
						column.children[0].innerText :
						column.innerText;
					}
				}
			}
		};

		let optionsTable = {
			language: localize({
				ru: dataLanguageConfig['ru'],
				en: dataLanguageConfig['en']
			}),
			dom: domStr,
			buttons: [
				{
					text: 'Скачать все отфильтрованные позиции',
					className: 'btn btn--bordered btn--excel js-tour--illiquids-btn',
					action: function (e, dt, node, config) {
						let params = dt.ajax.params(),
							generateXlsPath = node.closest('.table').attr('data-generate-xls-path'),
							downloadXlsPath = node.closest('.table').attr('data-download-xls-path');

						node.addClass('btn--disabled btn--loading');
						$(document)
							.find('.btn--illiquids-mobile')
							.addClass('btn--disabled btn--loading');
						$.post(generateXlsPath, params, function (resTask) {
							if (resTask.success) {

								var intervalGetFile = setInterval(function () {
									$.post(downloadXlsPath, {
										task_id: resTask.task_id
									}, function (resFile) {
										if (resFile.success) {
											showNotyfications(resFile.msg, {type: 'success'});
											if (resFile.status == 'created') {
												downloadFile(resFile.zip);
												clearInterval(intervalGetFile);
												node.removeClass('btn--disabled btn--loading');
												$(document)
													.find('.btn--illiquids-mobile')
													.removeClass('btn--disabled btn--loading');
											}
										} else if (resFile.error) {
											showNotyfications(resFile.msg, {type: 'error'});
											clearInterval(intervalGetFile);
											node.removeClass('btn--disabled btn--loading');
											$(document)
												.find('.btn--illiquids-mobile')
												.removeClass('btn--disabled btn--loading');
										}
									}, 'json');
								}, 1000);

							} else if (resTask.error) {
								showNotyfications(resTask.msg, {type: 'error'});
								node.removeClass('btn--disabled btn--loading');
								$(document)
									.find('.btn--illiquids-mobile')
									.removeClass('btn--disabled btn--loading');
							}
						}, 'json');
					}
				},
				{
					text: 'Скачать выбранные',
					className: 'btn btn--bordered js-tour--illiquids-btn btn--disabled js-download-rows',
					action: function (e, dt, node, config) {
						let params = {
								selected: cookie.get('DT_SELECTED_ROWS')
							},
							generateXlsPath = node.closest('.table').attr('data-generate-xls-path'),
							downloadXlsPath = node.closest('.table').attr('data-download-xls-path');

						node.addClass('btn--disabled btn--loading');
						$.post(generateXlsPath, params, function (resTask) {
							if (resTask.success) {

								var intervalGetFile = setInterval(function () {
									$.post(downloadXlsPath, {
										task_id: resTask.task_id
									}, function (resFile) {
										if (resFile.success) {
											showNotyfications(resFile.msg, {type: 'success'});
											if (resFile.status == 'created') {
												downloadFile(resFile.zip);
												clearInterval(intervalGetFile);
												node.removeClass('btn--disabled btn--loading');
											}
										} else if (resFile.error) {
											showNotyfications(resFile.msg, {type: 'error'});
											clearInterval(intervalGetFile);
											node.removeClass('btn--disabled btn--loading');
										}
									}, 'json');
								}, 1000);

							} else if (resTask.error) {
								showNotyfications(resTask.msg, {type: 'error'});
								node.removeClass('btn--disabled btn--loading');
							}
						}, 'json');
					}
				},
				{
					text: 'Посмотреть выбранные',
					className: 'btn btn--bordered js-tour--illiquids-btn btn--disabled js-chosen-rows',
					action: function (e, dt, node, config) {
						node.addClass('btn--disabled btn--loading');
						location.href += '?showSelected=Y';
					}
				},
				{
					text: 'Посмотреть все',
					className: 'btn btn--bordered js-all-rows',
					action: function (e, dt, node, config) {
						node.addClass('btn--disabled btn--loading');
						location.href = location.href.split('/?')[0];
					}
				},
				{
					className: 'tour-btn--start',
					titleAttr: 'Запустить тур',
					action: function () {
						$(document).find('.data-table-scroll')[0].scrollLeft = 0;
						illiquidsTourInit();
					}
				}
			],
			bSort: false,
			processing: true,
			serverSide: true,
			ajax: {
				url: path,
				type: 'POST',
				data: function (d) {
					if ($('[name="filter-local-tenders"]:checked').val() !== undefined && $('[name="filter-local-tenders"]:checked').val() !== '') {
						d.company = $('[name="filter-local-tenders"]:checked').val();
					}

					if (getUrlParam('sort') !== null && getUrlParam('sort') !== undefined && getUrlParam('sort') == 'active_from') {
						d.sort = 'active_from';
					}
					if (getUrlParam('archive') !== null && getUrlParam('archive') !== undefined) {
						d.archive = true;
					}
					if (getUrlParam('showSelected') !== null && getUrlParam('showSelected') !== undefined) {
						d.selected = cookie.get('DT_SELECTED_ROWS');
					}
				},
				complete: function (r) {
					if (r.responseJSON.priorityTr !== undefined) {
						r.responseJSON.priorityTr.forEach(function (id) {
							$(table).find(`tr#${id}`).addClass('priority');
						});
						self.setCheckedRows(r.responseJSON.data);
					}
				},
				error: function () {
					console.error('error');
				}
			},
			columns: dataColumns
		};

		if ($(table).closest('.table').attr('id') == 'tenders-table-illiquids') {
			optionsTable.language.zeroRecords = dataLanguageConfig['ru'].zeroRecords.illiquids;
			optionsTable.language.emptyTable = dataLanguageConfig['ru'].emptyTable.illiquids;
		} else {
			optionsTable.language.zeroRecords = dataLanguageConfig['ru'].zeroRecords.tenders;
			optionsTable.language.emptyTable = dataLanguageConfig['ru'].emptyTable.tenders;
		}

		if ($(table).closest('.table').attr('id') == 'tenders-table-illiquids') {
			optionsTable.select = {
				style: 'multi',
				info: false,
				selector: '.js-checkbox--select-row'
			};
		}

		// Init Data Table
		self.dt = $(table)
			.on('preDraw.dt', function () {
				$(table)
					.find('.checkbox__label--all')
					.removeClass('checkbox__label--checked')
					.find('input')
					.prop('checked', false);

				$(document)
					.find('.js-checkbox--select-row')
					.off('change');

				if (getUrlParam('showSelected') !== null && getUrlParam('showSelected') !== undefined) {
					$('.dataTables_filter').hide();
					$('.tenders-filter__btn').hide();
					$('.js-all-rows').show();
					$('.js-chosen-rows').hide();
					$('.tour-btn--start').hide();
				} else {
					$('.dataTables_filter').show();
					$('.tenders-filter__btn').show();
					$('.js-all-rows').hide();
					$('.js-chosen-rows').show();
					$('.tour-btn--start').show();
				}

				let currentWidth = verge.viewportW(),
					mobile = false;
				if (currentWidth <= 768) {
					mobile = true;
				}

				if (cookie.get('DT_SELECTED_ROWS') || mobile) {
					$(document).find('.js-tour--illiquids-btn').removeClass('btn--disabled');
				} else {
					$(document).find('.js-tour--illiquids-btn').addClass('btn--disabled');
				}
			})
			.on('draw.dt', function () {
				$(document)
					.find('.js-checkbox--select-row')
					.on('change', function () {
						self.selectRow(this);
					});

				$(document)
					.find(`.js-checkbox--select-row:not('.js-checkbox--select-row-all')`)
					.each(function (i, el) {
						$(el).attr('id', `chk-row-${$(el).closest('tr').attr('id')}`);
						$(el).closest('.checkbox__label').attr('for', `chk-row-${$(el).closest('tr').attr('id')}`);
					});
			})
			.on('init.dt', function () {
				self.selectCreate();
				self.datepickerCreate();
				self.popupsCreate();
				self.filterLocalTenders();

				let tourProgress = cookie.get('TOUR_COMPLETED');

				// возможно вынести =>
				let currentWidth = verge.viewportW(),
					mobile = false;
				if (currentWidth <= 768) {
					mobile = true;
				}
				// <=

				if ((tourProgress !== '1') && (!mobile) && $(document).find('#tenders-table-illiquids').length) {
					if (getUrlParam('showSelected') == null || getUrlParam('showSelected') == undefined) {
						illiquidsTourInit();
					}
				}
			})
			.dataTable(optionsTable);

		$(document).on('change', $(table).find('.js-checkbox'), debounce(300, function (e) {
			if ($(e.target).closest('.tenders-filter').length) {
				self.filterEvent($(e.target).closest('th')[0]);
			}
		}));

		// Reset Filter
		$(table)
			.find('.tenders-filter__btn--reset')
			.on('click', function () {
				let $th = $(this).closest('.tenders-filter'),
					$arCheckBoxes = $(this).siblings('.checkbox').find('.checkbox__label');
				self.filterReset($arCheckBoxes, $th);
			});
	}

	filterLocalTenders() {
		$('[name="filter-local-tenders"]').on('change', () => {
			let parsed = queryString.parse(location.search);
			parsed.company = $('[name="filter-local-tenders"]:checked').val();
			location.search = queryString.stringify(parsed);
		});
	}

	popupsCreate() {
		let self = this;

		$(this.table)
			.find('.tenders-filter__btn')
			.on('click', function () {
				if (!($(this).hasClass('is-expand'))) {
					$(this)
						.closest('.js-data-table--custom')
						.find('.is-expand')
						.removeClass('is-expand');

					$(this)
						.addClass('is-expand')
						.siblings('.tenders-filter__popup')
						.addClass('is-expand');

					$(document)
						.find('.data-table-scroll')
						.css('min-height', `${$('.data-table-scroll > table > thead').outerHeight() + $('.tenders-filter__popup.is-expand').outerHeight()}px`);
				} else {
					$(this)
						.removeClass('is-expand')
						.siblings('.tenders-filter__popup')
						.removeClass('is-expand');
					$(document)
						.find('.data-table-scroll')
						.css('min-height', '');
				}
			});

		// Click Overlay
		$(document).on('click', function (e) {
			let $isExpandedFilter = $(e.target)
					.closest('.tenders-filter')
					.find('.tenders-filter__popup.is-expand')
					.length,
				$isExpandedFilterBtn = $(e.target)
					.closest('.tenders-filter')
					.find('.tenders-filter__btn.is-expand')
					.length,
				$isDatepicker = $(e.target)
					.closest('[class^="datepicker"]')
					.length;

			let currentWidth = verge.viewportW(),
				mobile = false;
			if (currentWidth <= 768) {
				mobile = true;
			}

			if (!($isExpandedFilter) && !($isExpandedFilterBtn) && !($isDatepicker) && !mobile) {
				self.popupsClose();
			}
		});

		if (!$('.btn--illiquids-mobile').length && $('#tenders-table-illiquids').length) {
			let $btnExcel = $(document).find('.btn--excel'),
				$cloneBtnExcel = $btnExcel.clone();

			$cloneBtnExcel.addClass('btn--illiquids-mobile');
			$cloneBtnExcel.insertAfter($('#tenders-table-illiquids').find('.data-table-scroll'));

			$cloneBtnExcel.on('click', function (event) {
				event.preventDefault();
				$btnExcel.trigger('click');
			});
		}

		let $labelNoFound = $('<label>Результатов не найдено</label>'),
			options = {
				valueNames: ['tenders-filter__val'],
				listClass: 'tenders-filter__list',
				searchClass: 'tenders-filter__search',
				updated: function (list) {
					if (list.matchingItems.length == 0) {
						$(list.list).append($labelNoFound);
					} else {
						$labelNoFound.detach();
					}
				}
			};
		let $popup = $('.tenders-filter__list').closest('.tenders-filter__popup');

		$popup.each(function (i, el) {
			if ($(el).is('[data-searchable]')) {
				// tendersFilterLists[i] = new List($(el)[0], options);
				self.tendersFilterLists[i] = new List(el, options);
			}
		});

		// SEARCH IN FILTER LOCAL ROKS
		var modOptions = options;
		modOptions.valueNames = ['radio__value'];
		modOptions.listClass = 'radio';
		modOptions.searchClass = 'radio-filter__search';

		var tendersLocalRoksRadio = document.getElementById('tenders-local-roks__radio'),
			tendersLocalRoksRadioSearch = new List(tendersLocalRoksRadio, modOptions);
	}

	popupsClose() {
		$(this.table)
			.find('.tenders-filter__popup.is-expand')
			.removeClass('is-expand');
		$(this.table)
			.find('.tenders-filter__btn.is-expand')
			.removeClass('is-expand');
		$(document)
			.find('.data-table-scroll')
			.css('min-height', '');
	}

	datepickerCreate() {
		let self = this;
		let datepicker = $(this.table)
			.find('#tenders-filter-datepicker')
			.datepicker({
				range: true,
				inline: true,
				toggleSelected: false,
				multipleDatesSeparator: '-',
				clearButton: true,
				onSelect: function (formattedDate, date, inst) {
					let val = '';

					if (date.length == 2) {
						val = moment(date[0]).format('X') + '-' + moment(date[1]).endOf('day').format('X');
						self.filterEvent(inst.el.closest('th'), val);
						self.popupsClose();
					}

					if (!date.length) {
						self.filterEvent(inst.el.closest('th'), '');
						self.popupsClose();
					}
				}
			});
	}

	selectCreate() {
		$(document)
			.find('.dataTables_length')
			.find('select')
			.select2({
				theme: 'filled',
				minimumResultsForSearch: Infinity
			});

		let $selectSort = $(document)
							.find('.dataTables_sort')
							.detach();
		$(document)
			.find('.dataTables_length')
			.after($selectSort);

		$(document)
			.find('.dataTables_sort')
			.show()
			.find('select')
			.select2({
				theme: 'filled',
				minimumResultsForSearch: Infinity
			})
			.on('change', function(event) {
				if ($(this).val() !== '') {
					if (location.search[0] == '?') {
						location.href += `&sort=${$(this).val()}`;
					} else {
						location.href += `?sort=${$(this).val()}`;
					}
				} else {
					removeParams('sort');
					location.reload();
				}
			});
	}

	filterEvent(th, val) {
		let self = this,
			colNum = 1,
			filterLength = 0,
			filterVal = '';

		if ($(th).find('.js-checkbox').length) {
			let $arCheckBoxesChecked = $(th).find('.js-checkbox:checked');
			let arVals = $arCheckBoxesChecked.map(function (i, el) {
				return $(el).siblings('.tenders-filter__val').text();
			});

			filterLength = $arCheckBoxesChecked.length;
			colNum = $(th).attr('data-col');
			filterVal = $.makeArray(arVals).join('|');
		} else if ($(th).find('#tenders-filter-datepicker').length) {
			if (val !== '') {
				filterLength = 1;
			}
			colNum = $(th).attr('data-col');
			filterVal = val;
		}

		self.filterStart(colNum, filterVal);
		self.filterColStateToggle(th, filterLength);
	}

	filterColStateToggle(th, length) {
		if (length) {
			$(th)
				.find('.tenders-filter__btn')
				.addClass('is-filtered');
		} else {
			$(th)
				.find('.tenders-filter__btn')
				.removeClass('is-filtered');
		}
	}

	filterStart(col, val) {
		$(this.table).DataTable().column(col).search(
			val,
			true,
			false
		).draw();
	}

	filterReset($arCheckBoxes, $th) {
		let self = this;
		$arCheckBoxes.each(function () {
			let colNum = $(this).closest('.tenders-filter').attr('data-col');
			$(this)
				.removeClass('checkbox__label--checked')
				.find('.js-checkbox')
				.prop('checked', false)
				.closest('.tenders-filter')
				.find('.tenders-filter__btn')
				.removeClass('is-filtered');

			$(this)
				.find('.js-checkbox')
				.trigger('change');

			// self.filterStart(colNum, '');
			// self.popupsClose();
		});


		let indexSearchFilter = $.makeArray($(self.table).find('[data-searchable]')).indexOf($th.find('[data-searchable]')[0]);
		if (indexSearchFilter >= 0) {
			$th.find('.tenders-filter__search').val('');
			self.tendersFilterLists[indexSearchFilter].search();
		}
	}

	selectRow(checkbox) {
		let self = this,
			$this = $(checkbox),
			$label = $this.parent('.checkbox__label'),
			$labelAllArray = $(document).find('.js-checkbox--select-row').parent('.checkbox__label');

		if ($this.hasClass('js-checkbox--select-row-all')) {
			if ($label.hasClass('checkbox__label--checked')) {
				$labelAllArray.each(function (index, el) {
					$(el)
						.removeClass('checkbox__label--checked')
						.find('input')
						.prop('checked', false);
					self.dt.api().rows().deselect();
					self.toggleSelectedCookie($(el).closest('tr'));
				});
			} else {
				$labelAllArray.each(function (index, el) {
					$(el)
						.addClass('checkbox__label--checked')
						.find('input')
						.prop('checked', true);
					self.dt.api().rows().select();
					self.toggleSelectedCookie($(el).closest('tr'));
				});
			}
		} else {
			$label.toggleClass('checkbox__label--checked');

			// скорее всего это нужно
			if ($label.hasClass('checkbox__label--checked')) {
				$label.find('input').prop('checked', true);
			} else {
				$label.find('input').prop('checked', false);
			}
			//

			let $modifyArray = $labelAllArray.filter(function (i, el) {
				return !$(el).hasClass('checkbox__label--all');
			});


			var allChecked = true;
			$modifyArray.each(function (index, el) {
				if (!$(el).hasClass('checkbox__label--checked')) {
					allChecked = false;
				}
			});
			if (allChecked) {
				$(document)
					.find('.checkbox__label--all')
					.addClass('checkbox__label--checked')
					.find('input')
					.prop('checked', true);
			} else {
				$(document)
					.find('.checkbox__label--all')
					.removeClass('checkbox__label--checked')
					.find('input')
					.prop('checked', false);
			}

			self.toggleSelectedCookie($this.closest('tr'));
		}

		let currentWidth = verge.viewportW(),
			mobile = false;
		if (currentWidth <= 768) {
			mobile = true;
		}

		if ($(document).find('.js-checkbox--select-row:checked').length || cookie.get('DT_SELECTED_ROWS') || mobile) {
			$(document).find('.js-tour--illiquids-btn').removeClass('btn--disabled');
		} else {
			$(document).find('.js-tour--illiquids-btn').addClass('btn--disabled');
		}
	}

	toggleSelectedCookie(dtSelectedRow) {
		let	dtSelectedRowId = dtSelectedRow.attr('id'),
			dtCookie = cookie.get('DT_SELECTED_ROWS');

		let dtCookieMatchTemplate = new RegExp(`${dtSelectedRowId}#|#${dtSelectedRowId}$|${dtSelectedRowId}$`, 'g');

		if (dtSelectedRow.find('.js-checkbox--select-row').prop('checked')) {
			if (dtCookie != undefined && dtCookie.length > 0) {
				if (!dtCookie.match(dtCookieMatchTemplate)) {
					dtCookie += '#' + dtSelectedRowId;
				}
			} else {
				dtCookie = dtSelectedRowId;
			}

			cookie.set('DT_SELECTED_ROWS', dtCookie);

		} else {
			if (dtCookie != undefined && dtCookie.length > 0) {
				let arrDtCookie = dtCookie.split('#');
				if (arrDtCookie.length) {
					arrDtCookie = arrDtCookie.filter(function (el, i) {
						return el.length;
					});
				}
				if (arrDtCookie.length) {
					let index = arrDtCookie.indexOf(dtSelectedRowId);
					if (index >= 0) {
						arrDtCookie = arrDtCookie.filter(function (obj, i) {
							return i !== parseInt(index);
						});
					}
				}

				if (arrDtCookie.length > 0) {
					dtCookie = arrDtCookie.join('#');
					cookie.set('DT_SELECTED_ROWS', dtCookie);
				} else {
					cookie.removeSpecific('DT_SELECTED_ROWS');
				}
			}
		}
	}

	setCheckedRows(data) {
		let self = this,
			dtCookie = cookie.get('DT_SELECTED_ROWS');

		if (dtCookie) {
			let dtSetCheckboxes = cookie.get('DT_SELECTED_ROWS').split('#');
			let filteredDataEl = data.filter(function (el) {
				return dtSetCheckboxes.indexOf(el.DT_RowId) >= 0;
			});

			$.each(filteredDataEl, function (i, el) {
				self.dt.api().rows(`#${el.DT_RowId}`).select();
				let checkbox = $(`tr[id="${el.DT_RowId}"]`)
								.find('.js-checkbox--select-row');
				self.selectRow(checkbox);
			});
		}
	}
}

if ($('#tenders-table-centralized').length) {
	let TendersCentr = new SuppliersTable($('#tenders-table-centralized').find('.js-data-table--custom'));
	TendersCentr.create();
}

if ($('#tenders-table-local').length) {
	let TendersLocal = new SuppliersTable($('#tenders-table-local').find('.js-data-table--custom'));
	TendersLocal.create();
}

if ($('#tenders-table-illiquids').length) {
	let TendersIlliquids = new SuppliersTable($('#tenders-table-illiquids').find('.js-data-table--custom'));
	TendersIlliquids.create();
}

// Стилизация поиска =>
$('.dataTables_filter input').on('focus', function () {
	$(this).css('background-color', '#ffffff');
});

$('div.dataTables_filter input').on('focusout', function () {
	if ($(this).val() == '') {
		$(this).css('background-color', 'transparent');
	}
});
