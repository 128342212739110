<template>
    <nav v-if="list" :class="['nav-mobile', { 'nav-mobile--opened': navOpened }]">
        <a href="#" class="nav-mobile__toggle" @click.prevent="toggleNav">
            <svg v-show="!navOpened" version="1.1" viewBox="0 0 24 24" class="z-icon z-fill" style="width: 36px; height: 36px;"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.4 6a.6.6 0 01.6-.6h18a.6.6 0 110 1.2H3a.6.6 0 01-.6-.6zm0 6a.6.6 0 01.6-.6h18a.6.6 0 110 1.2H3a.6.6 0 01-.6-.6zm.6 5.4a.6.6 0 100 1.2h18a.6.6 0 100-1.2H3z" fill="#0077C8"></path></svg>
            <svg v-show="navOpened" version="1.1" viewBox="0 0 24 24" class="z-icon z-fill" style="width: 30px; height: 30px;"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.15 12l-5.575 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.848 12l5.576-5.575z" fill="#0077C8"></path></svg>
        </a>

        <div class="nav-mobile__container" v-show="navOpened">
            <div class="container">
                <ul
                    v-show="navOpened"
                    ref="menuHeightStyle"
                    :class="[
                        'nav-mobile__list nav-mobile__list--1',
                        { 'nav-mobile__list--sub-opened': navIndexOpened >= 0 }
                    ]"
                >
                    <li
                        :class="[
                            'nav-mobile__item nav-mobile__item--1',
                            {
                                'is-opened': indexA == navIndexOpened,
                                'is-active': itemA.active
                            }
                        ]"
                        v-for="(itemA, indexA) in list"
                        :key="indexA"
                    >
                        <a
                            :href="itemA.link"
                            @click="showChilds($event, indexA, itemA)"
                            :blank="itemA.blank ? '_blank' : false"
                            :class="[
                                'nav-mobile__link nav-mobile__link--1',
                                { 'is-active': itemA.active }
                            ]"
                        >
                            <span v-html="itemA.name"></span>
                            <svg v-if="itemA.items && itemA.items.length" version="1.1" viewBox="0 0 24 24" class="z-icon z-fill z-right" style="width: 20px; height: 20px; transform: rotate(90deg);"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.424 8.575a.6.6 0 00-.848 0l-6 6a.6.6 0 00.848.849L12 9.848l5.576 5.576a.6.6 0 00.848-.849l-6-6z" fill="#0077C8"></path></svg>
                        </a>

                        <div class="nav-mobile__childs" v-if="itemA.items">
                            <div class="nav-mobile__inner" ref="menuHeight">
                                <div class="nav-mobile__childs-header">
                                    <button
                                        class="nav-mobile__close"
                                        @click.prevent="hideChilds(indexA)"
                                    >
                                        <svg version="1.1" viewBox="0 0 32 32" class="z-icon z-fill z-left" style="width: 23px; height: 23px; transform: rotate(-90deg);"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16 1.2c8.174 0 14.8 6.626 14.8 14.8S24.174 30.8 16 30.8 1.2 24.174 1.2 16 7.826 1.2 16 1.2zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm.965 10.353a.698.698 0 00-.964.03l-4.621 4.62a.7.7 0 10.99.99l3.429-3.428v7.557a.7.7 0 101.4 0v-7.557l3.428 3.428a.7.7 0 00.99-.99l-4.623-4.623a.741.741 0 00-.03-.027z" fill="#0077C8"></path></svg>
                                        <span>{{ closeText }}</span>
                                    </button>
                                </div>
                                <div class="container nav-mobile__childs-container">
                                    <a
                                        :href="!isRedirect(itemA) ? itemA.link : '#'"
                                        :blank="itemA.blank ? '_blank' : false"
                                        :class="[
                                            'nav-mobile__link nav-mobile__link--overview',
                                            { 'is-active': checkOverviewActive(itemA.link) }
                                        ]"
                                    >
                                        <span v-html="itemA.name"></span>
                                        <svg v-if="!isRedirect(itemA)" version="1.1" viewBox="0 0 32 32" class="z-icon z-fill z-left" style="width: 23px; height: 23px; transform: rotate(90deg);"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16 1.2c8.174 0 14.8 6.626 14.8 14.8S24.174 30.8 16 30.8 1.2 24.174 1.2 16 7.826 1.2 16 1.2zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm.965 10.353a.698.698 0 00-.964.03l-4.621 4.62a.7.7 0 10.99.99l3.429-3.428v7.557a.7.7 0 101.4 0v-7.557l3.428 3.428a.7.7 0 00.99-.99l-4.623-4.623a.741.741 0 00-.03-.027z" fill="#0077C8"></path></svg>
                                    </a>

                                    <ul class="nav-mobile__list nav-mobile__list--2">
                                        <li
                                            :class="[
                                                'nav-mobile__item nav-mobile__item--2',
                                                {
                                                    'is-active': itemB.active,
                                                    'is-opened': indexB == navChildsIndexOpened
                                                }
                                            ]"
                                            v-for="(itemB, indexB) in clearHide(itemA.items)"
                                            :key="indexB"
                                        >
                                            <a
                                                :href="itemB.link"
                                                :class="[
                                                    'nav-mobile__link nav-mobile__link--2',
                                                    { 'is-active': itemB.active }
                                                ]"
                                                :blank="itemB.blank ? '_blank' : false"
                                                @click="showSubChilds($event, indexB, itemB)"
                                            >
                                                <span v-html="itemB.name"></span>
                                                <svg
                                                    :style="`width: 20px; height: 20px; ${navChildsIndexOpened === indexB ? 'transform: rotate(0deg);' : 'transform: rotate(180deg);'}`"
                                                    v-if="itemB.items && itemB.items.length"
                                                    version="1.1" viewBox="0 0 24 24" class="z-icon z-fill"
                                                ><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.424 8.575a.6.6 0 00-.848 0l-6 6a.6.6 0 00.848.849L12 9.848l5.576 5.576a.6.6 0 00.848-.849l-6-6z" fill="#0077C8"></path></svg>
                                            </a>
                                            <div class="nav-mobile__subchilds" v-if="itemB.items">
                                                <ul class="nav-mobile__list nav-mobile__list--3">
                                                    <li
                                                        :class="[
                                                            'nav-mobile__item nav-mobile__item--3',
                                                            { 'is-active': itemC.active }
                                                        ]"
                                                        v-for="(itemC, indexC) in clearHide(
                                                            itemB.items
                                                        )"
                                                        :key="indexC"
                                                    >
                                                        <a
                                                            :href="itemC.link"
                                                            :class="[
                                                                'nav-mobile__link nav-mobile__link--3',
                                                                { 'is-active': itemC.active }
                                                            ]"
                                                            :blank="itemC.blank ? '_blank' : false"
                                                            v-html="itemC.name"
                                                        ></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <ul v-show="isNavFooterShown" class="nav-mobile__footer-list">
                    <li class="nav-mobile__footer-item">
                        <a href="/sitemap/" class="nav-mobile__footer-link">Карта сайта</a>
                    </li>
                    <li class="nav-mobile__footer-item">
                        <a href="/terms/" class="nav-mobile__footer-link">Условия использования</a>
                    </li>
                    <li class="nav-mobile__footer-item">
                        <a href="#" data-type="inline" data-content="all-sites" class="link--all-sites js-link-all-sites js-modal nav-mobile__link-cites">
                            <span v-html="text.otherSites"></span>
                            <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill z-right" style="transform: rotate(90deg); width: 20px; height: 20px;"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.424 8.575a.6.6 0 00-.848 0l-6 6a.6.6 0 00.848.849L12 9.848l5.576 5.576a.6.6 0 00.848-.849l-6-6z" fill="#0077C8"></path></svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'navigation-mobile',
    data () {
        return {
            navOpened: false,
            isNavFooterShown: false,
            navIndexOpened: -1,
            navChildsIndexOpened: -1,
            arrowDirection: 'down',
            heightStyles: {},
            closeText: localize({
                ru: 'Главное меню',
                en: 'Main menu'
            }),
            text: {
                otherSites: localize({
                    ru: 'Сайты Группы',
                    en: 'Group Sites'
                })
            }
        }
    },
    computed: {
        list () {
            let list = []
            let cacheI = 0
            App.components.navigation.top.forEach((item, i) => {
                let obj = {
                    type: 'link',
                    index: i,
                    name: item.TEXT,
                    link: item.LINK,
                    level: Number(item.DEPTH_LEVEL),
                    parent: item.IS_PARENT,
                    permission: item.PERMISSION,
                    params: item.PARAMS,
                    active: item.SELECTED
                }
                if (obj.parent) {
                    obj.items = []
                }

                if (obj.level > 1) {
                    let objParent = this.deepSearch(list, 'index', (k, v) => v === cacheI)
                    if (obj.level <= objParent.level) {
                        list[list.length - 1].items.push(obj)
                    } else {
                        objParent.items.push(obj)
                    }
                } else {
                    list.push(obj)
                }

                if (obj.parent) {
                    cacheI = i
                }
            })

            return list
        }
    },
    updated () {
        this.matchHeight()
    },
    methods: {
        deepSearch (object, key, predicate) {
            if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) return object

            for (let i = 0; i < Object.keys(object).length; i++) {
                let value = object[Object.keys(object)[i]];
                if (typeof value === "object" && value != null) {
                    let o = this.deepSearch(object[Object.keys(object)[i]], key, predicate)
                    if (o != null) return o
                }
            }
            return null
        },
        matchHeight () {
            if (this.navIndexOpened !== -1) {
                let heightString = this.$refs.menuHeight[this.navIndexOpened].clientHeight + 'px'
                this.$refs.menuHeightStyle.setAttribute('style', `min-height: ${heightString}`)
            } else {
                this.$refs.menuHeightStyle.setAttribute('style', ``)
            }
        },
        buildTarget (href) {
            return /^http|^\/\//.test(href) ? '_blank' : '_self'
        },
        isActive (path) {
            return window.location.pathname === path
        },
        isRedirect (item) {
            return item.params.redirect
        },
        checkOverviewActive (link) {
            return location.pathname === link
        },
        showChilds (event, i, item) {
            if (!item.hasOwnProperty('items')) {
                this.isNavFooterShown = true
                this.hideChilds()
            } else if (
                item.hasOwnProperty('items') &&
                item.items.length &&
                this.navIndexOpened !== i
            ) {
                this.isNavFooterShown = false
                event.preventDefault()
                this.navIndexOpened = i
                this.navChildsIndexOpened = -1
            }
        },
        showSubChilds (event, i, item) {
            if (this.navChildsIndexOpened === i) {
                event.preventDefault()
                this.navChildsIndexOpened = -1
                return
            }

            if (
                item.hasOwnProperty('items') &&
                item.items.length &&
                this.navChildsIndexOpened !== i
            ) {
                event.preventDefault()
                this.navChildsIndexOpened = i
            }
        },
        hideChilds () {
            this.isNavFooterShown = true
            this.navIndexOpened = -1
            this.navChildsIndexOpened = -1
        },
        toggleNav () {
            if (!this.navOpened) {
                this.navOpened = true
                this.isNavFooterShown = true
                // this.MenuOpen(true)
                document.body.style.overflow = 'hidden'
                document.querySelector('body').classList.add('nav-opened')
            } else {
                this.navOpened = false
                this.isNavFooterShown = false
                document.body.style.overflow = ''
                document.querySelector('body').classList.remove('nav-opened')
            }
        },
        clearHide (data) {
            return data.filter((item) => !item.hide)
        }
    }
}
</script>
