function closeFilterSearch() {
	$('.js-setings-switch').removeClass('search-results__setings-switch--expanded');
	$('.js-search-settings').addClass('search-results__setings--hidden');
}

/* search GPN*/
$('.search-results').on('click', '.search-results__sections-btn', function (event) {
	event.preventDefault();
	var $searchResults = $('.search-results__return');
	$('.search-results__sections-btn').removeClass('search-results__sections-btn--expanded');
	$(this).addClass('search-results__sections-btn--expanded');

	$searchResults
		.removeClass('search-data')
		.html('<div class="col-default-12 news__preloader"><img src="/images/svg/loader.svg"></div>');

	$.get($(this).attr('data-url'), function (data) {
		let subData = data.substring(data.indexOf('class="search-results__return"') + 31, data.length);
		$searchResults
			.find('.news__preloader')
			.remove();
		$searchResults
			.addClass('search-data')
			.html(subData);
	});
});

$('.search-results').on('click', '.search__example .link--dots', function (event) {
	event.preventDefault();
	$('#search_q').val($('.search__example .link--dots').html());
});

$('.search-results').on('click', '.js-setings-switch', function (event) {
	event.preventDefault();
	$(this).toggleClass('search-results__setings-switch--expanded');
	$('.js-search-settings').toggleClass('search-results__setings--hidden');
});

$('.search-results').on('click', '.js-search-settings__close', function (event) {
	event.preventDefault();
	closeFilterSearch();
});

// $('.search-results').on('click', '.js-setings-set-period', function(event) {
// 	event.preventDefault();
// 	$(this).toggleClass('is-expanded');
// 	$('.js-set-period').toggleClass('search-results__setings-period--expanded');
// });

// $('.search-results').on('click', '.js-set-period-close-btn', function(event) {
// 	event.preventDefault();
// 	$('.js-set-period, .js-setings-set-period').removeClass('search-results__setings-period--expanded');
// });

$('.search-results').on('click', '.js-settings-select-period', function (event) {
	event.preventDefault();
	// $('#date-from').val($(this).attr('data-from'));
	// $('#date-to').val($(this).attr('data-to'));
	$('.js-settings-select-period.search-results__setings-period--expanded').removeClass('search-results__setings-period--expanded');
	$(this).addClass('search-results__setings-period--expanded');
});

$('.search-results').on('click', '.js-tag-remove', function (event) {
	event.preventDefault();
	location.href = $(this).attr('data-url');
});

/* end of search */

$(document).on('keyup', function (e) {
	if (e.keyCode === 27 && !$('.js-search-settings').hasClass('search-results__setings--hidden')) {
		closeFilterSearch();
	}
});
$(document).on('click', function (e) {
	if (!$('.js-search-settings').hasClass('search-results__setings--hidden')) {
		if (
			!$('.js-search-settings').is(e.target) &&
			$('.js-search-settings').has(e.target).length === 0 &&
			!$('.js-setings-switch').is(e.target) &&
			$('.js-setings-switch').has(e.target).length === 0 &&
			!$('.pika-select').is(e.target)
			) {
				closeFilterSearch();
		}
	}
});
