import Vue from 'vue';
import TendersPage from "./TendersPage.vue"

const container = document.getElementById('tenders-page');
const exists = typeof(container) != 'undefined' && container != null;

if (exists) TendersPageApp();

function TendersPageApp() {
    new Vue({
        el: '#tenders-page',
        components: {
            TendersPage
        }
    });
};
