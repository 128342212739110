var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data && _vm.data.length
    ? _c(
        "div",
        { staticClass: "tenders-page" },
        _vm._l(_vm.data, function(item, index) {
          return _c(
            "div",
            { key: index + item.name, staticClass: "tenders-page__item" },
            [
              _c("div", { staticClass: "tenders-page__date" }, [
                _vm._v("\n                Срок подачи предложений: "),
                _c("span", [
                  _vm._v("до " + _vm._s(_vm.getDate(item.endDate.timestamp)))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tenders-page__name" }, [
                _vm._v(
                  "\n                " + _vm._s(item.name) + "\n            "
                )
              ]),
              _vm._v(" "),
              item.files.length
                ? _c("div", { staticClass: "tenders-page__files" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tenders-page__attach",
                        on: {
                          click: function($event) {
                            return _vm.toggleFiles($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    Приложения\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "file-list tenders-page__list" },
                      _vm._l(item.files, function(file, i) {
                        return _c("ul", { staticClass: "file-list__group" }, [
                          _c(
                            "li",
                            {
                              key: i + file.name,
                              class: [
                                "file-list__item",
                                "file-list__item--" + file.type
                              ]
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "link file-list__link",
                                  attrs: { href: file.link, target: "_blank" }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(file.name) +
                                      "\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "file-list__size" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(file.size) +
                                    "\n                        "
                                )
                              ])
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-703fecf4", { render: render, staticRenderFns: staticRenderFns })
  }
}