<template>
    <div class="tenders-page" v-if="data && data.length">
        <div class="tenders-page__item" v-for="(item, index) in data" :key="index + item.name">
                <div class="tenders-page__date">
                    Срок подачи предложений: <span>до {{ getDate(item.endDate.timestamp) }}</span>
                </div>
                <div class="tenders-page__name">
                    {{ item.name }}
                </div>
                <div class="tenders-page__files" v-if="item.files.length">
                    <div class="tenders-page__attach" @click="toggleFiles($event)">
                        Приложения
                    </div>
                    <div class="file-list tenders-page__list">
                        <ul class="file-list__group" v-for="(file, i) in item.files">
                            <li
                                :class="[
                                'file-list__item',
                                `file-list__item--${file.type}`
                                ]"
                                :key="i + file.name"
                            >
                                <a
                                    :href="file.link"
                                    class="link file-list__link"
                                    target="_blank"
                                >
                                    {{ file.name }}
                                </a>
                                <span class="file-list__size">
                                {{ file.size }}
                            </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: "tenders-page",
    props: {
        data: Array
    },
    methods: {
        getDate: function (date) {
            return moment.unix(date).format('DD.MM.YYYY')
        },
        toggleFiles (e) {
            e.target.parentNode.classList.toggle('tenders-page__files--active')
        }
    }
}
</script>